import { createTransform } from "redux-persist";

interface FilterOption {
  key: string;
  whitelists: string[];
}

const filter = function (options: FilterOption[], state: any, key: string) {
  for (let i = 0; i < options.length; i++) {
    const option = options[i];
    if (key === option.key) {
      return option.whitelists.reduce(
        (newState, whitelistKey) => ({
          ...newState,
          [whitelistKey]: state[whitelistKey],
        }),
        {}
      );
    }
  }
  return state;
};

export default function reduxPersistTransformFilter(options: FilterOption[]) {
  return createTransform(
    (inboundState, key) => filter(options, inboundState, key as string),
    (outboundState, key) => filter(options, outboundState, key as string),
    { whitelist: options.map((option) => option.key) }
  );
}
