import { ApiError, ApiResult, client } from "common/repositories";
import { UseQueryOptions, UseQueryResult, useQuery } from "react-query";

import { User } from "./auth.model";

export const getMe = async function (): Promise<User> {
  const response = await client.get("me").json<ApiResult<User>>();
  return response.data;
};

export function useTesting(
  options?: UseQueryOptions<ApiResult<{ id: string }[]>, ApiError>
): UseQueryResult<ApiResult<{ id: string }[]>, ApiError> {
  return useQuery<ApiResult<{ id: string }[]>, ApiError>(
    ["getItems", "items", undefined],
    options
  );
}
