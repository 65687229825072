import invariant from "invariant";
import * as React from "react";

interface SettingProviderProps {
  children: React.ReactNode;
}

interface ContextProps {
  setting: boolean;
  setSetting: (value: boolean) => void;
}

const settingContext = React.createContext<ContextProps>({
  setting: false,
  setSetting: () => {},
});

export function SettingProvider(props: SettingProviderProps) {
  const [setting, setSetting] = React.useState<boolean>(false);
  return (
    <settingContext.Provider value={{ setting, setSetting }}>
      {props.children}
    </settingContext.Provider>
  );
}

export function useSetting() {
  const context = React.useContext(settingContext);
  invariant(
    context !== undefined,
    "useSetting must be used inside settingContext.Provider"
  );
  return context;
}
