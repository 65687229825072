import { StitchesCss, createCss } from "@stitches/react";
export type { StitchesVariants } from "@stitches/react";

const stitches = createCss({
  prefix: "qrid-",
  theme: {
    colors: {
      // primary: "#047AFF",
      // primary: "#004CFF",
      primary: "#CFE0E9",
      primary10: "#FFF8E6",
      primary50: "#FFDB84",
      secondary: "#F6F9FB",
      ternary: "#F9FFFA",
      lightBlue: "#F1FAFF",
      lightYellow: "#FEDD4A",
      greenButton: "#60BC4F",
      subHeadingBlue: "#CFE0E9",
      darkGrey: "#6C757D",
      darkDescription: "#6E6E6E",
      lightDescription: "#A1A1A1",
      blue: "#0477FF",

      tagBackground: "#000000",
      tagText: "#FFFFFF",
      primaryButtonBackground: "#000000",

      white: "white",
      warningDefault: "#FB4E4E",
      errorDefault: "#FB4E4E",
      defaultFont: "#25282B",
      lightGrey: "#8497A7",
      allPorts: "#23758E",
      darkGreen: "#296854",
      lightGreen: "#FCFFF4",
      titleGrey: "#A0A4A8",
      lighterGreen: "#DAE9DA",
      black80: "#52575C",
      black60: "#A0A4A8",
      black40: "#CACCCF",
      black20: "#DBDDE0",
      black5: "#F9F9FA",
      black8: "#F8F8F8",
      black9: "#F4F4F4",
      eastBayDefault: "#46546B",
      successDefault: "#2AC769",
    },
    fontWeights: {
      regular: 400,
      // medium: 500,
      bold: 700,
    },
    fontSizes: {
      xxs: "13px",
      xs: "15px",
      sm: "18px",
      md: "24px",
      lg: "40px",
      xl: "56px",
      xxl: "80px",
    },
    lineHeights: {
      xs: "20px",
      sm: "28px",
      md: "40px",
      lg: "48px",
      xl: "66px",
      xxl: "80px",
    },
    sizes: {
      pageMaxWidth: "1504px",
      xs: 490,
      sm: 690,
      md: 740,
      lg: 900,
      xl: 1140,
      xxl: 1280,
      xxxl: 1540,
    },
    space: {
      xs: "8px",
      sm: "12px",
      md: "24px",
      lg: "36px",
      xl: "48px",
      xxl: "86px",
      xxxl: "108px",
      xxxxl: "156px",
    },
    zIndices: {
      header: 1000,
    },
  },
  media: {
    xss: "(min-width: 320px)",
    xs: "(min-width: 414px)",
    sm: "(min-width: 576px)",
    md: "(min-width: 768px)",
    lg: "(min-width: 992px)",
    xl: "(min-width: 1200px)",
    xxl: "(min-width: 1600px)",
  },
  utils: {
    p: (config) => (value: any) => ({
      paddingTop: value,
      paddingBottom: value,
      paddingLeft: value,
      paddingRight: value,
    }),
    pt: (config) => (value: any) => ({
      paddingTop: value,
    }),
    pr: (config) => (value: any) => ({
      paddingRight: value,
    }),
    pb: (config) => (value: any) => ({
      paddingBottom: value,
    }),
    pl: (config) => (value: any) => ({
      paddingLeft: value,
    }),
    px: (config) => (value: any) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    py: (config) => (value: any) => ({
      paddingTop: value,
      paddingBottom: value,
    }),

    m: (config) => (value: any) => ({
      marginTop: value,
      marginBottom: value,
      marginLeft: value,
      marginRight: value,
    }),
    mt: (config) => (value: any) => ({
      marginTop: value,
    }),
    mr: (config) => (value: any) => ({
      marginRight: value,
    }),
    mb: (config) => (value: any) => ({
      marginBottom: value,
    }),
    ml: (config) => (value: any) => ({
      marginLeft: value,
    }),
    mx: (config) => (value: any) => ({
      marginLeft: value,
      marginRight: value,
    }),
    my: (config) => (value: any) => ({
      marginTop: value,
      marginBottom: value,
    }),
    size: (config) => (value: any) => ({
      minWidth: value,
      minHeight: value,
      width: value,
      height: value,
      aspectRatio: "1 / 1",
    }),
  },
});

export type CSS = StitchesCss<typeof stitches>;

export const { styled, global, css, getCssString, theme } = stitches;
