const routes = {
  login: (redirect = "") => `/user/login?redirect=${redirect}`,
  home: "/user",
  profile: "/user/profile",
  asset: "/user/asset",
  history: "/user/history",
  team: "/user/team",
  notification: "/user/notification",
  setting: "/user/setting",
  itemDetail: (id) => `/user/asset/${id}`,
  itemServiceHistory: (id) => `/user/asset/${id}/history`,
  historyDetail: (id) => `/user/history/${id}`,
  historyPage: (page, keyword) =>
    `/user/history?page=${page}${keyword ? `&keyword=${keyword}` : ""}`,
  assetPage: (page, keyword) =>
    `/user/asset?page=${page}${keyword ? `&keyword=${keyword}` : ""}`,
  teamPage: (page, keyword) =>
    `/user/team?page=${page}${keyword ? `&keyword=${keyword}` : ""}`,
  itemServicePending: (id) => `/user/asset/${id}/service-pending`,
  informationGainPage: (id, action) =>
    `/user/asset/${id}/gain?action=${action}`,
  addTeamPage: "/user/team/add-team",
  informationLocationPage: (id, action) =>
    `/user/asset/${id}/location?action=${action}`,
  informationConsumerPage: (id, action) =>
    `/user/asset/${id}/consumer?action=${action}`,
  servicePage: (id, action) => `/user/asset/${id}/service?action=${action}`,
  stockServicePage: (id, serviceId, action, userServiceId) =>
    `/user/asset/${id}/stock-service?serviceId=${serviceId}&action=${action}${
      userServiceId ? `&userServiceId=${userServiceId}` : ""
    }`,
  assetListPage: "/user/asset/asset-list",
  assetTemplateListPage: (itemId, title) =>
    `/user/asset/asset-list/asset-template-list/${itemId}?title=${title}`,
  addAssetPage: (itemId, title, templateId) =>
    `/user/asset/asset-list/add-asset/${itemId}${
      templateId ? `/${templateId}` : ""
    }?title=${title}`,
  importAssetPage: `/user/asset/asset-list/import-asset`,

  notificationSettingPage: "/user/setting/notification-setting",
  userAuthorityPage: "/user/setting/user-authority",
  tutorialMenuPage: "/user/setting/tutorial-menu",
  tutorialDetailPage: (tutorialId) =>
    `/user/setting/tutorial-menu/tutorial-detail/${tutorialId}`,
  consumerSettingPage: "/user/setting/consumer-setting",
  consumerDetailPage: (consumerId) =>
    `/user/setting/consumer-setting/consumer-detail/${consumerId}`,
  addConsumerPage: "/user/setting/consumer-setting/add-consumer",
  editConsumerPage: (consumerId) =>
    `/user/setting/consumer-setting/consumer-detail/${consumerId}/edit-consumer`,
  locationSettingPage: "/user/setting/location-setting",
  locationDetailPage: (type, locationName) =>
    `/user/setting/location-setting/location-detail/${type}/${locationName}`,
  changePinPage: "/user/setting/change-pin",
  reportDownloadPage: "/user/setting/report-download",
  assetTypePage: "/user/setting/report-download/asset-type",
  choosePeriodPage: (type, itemId) =>
    `/user/setting/report-download/choose-period?type=${type}${
      itemId ? `&itemId=${itemId}` : ""
    }`,
  changePhoneNumberPage: "/user/setting/change-phone-number",
  deleteAccountPage: "/user/setting/delete-account",
  confirmationDeleteAccount:
    "/user/setting/delete-account/confirmation-delete-account",
};

export default routes;
