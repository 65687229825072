import { Text } from "components/elements";
import * as React from "react";
import {
  ToastProvider,
  DefaultToast,
  ToastProps,
} from "react-toast-notifications";

interface Props {
  children: React.ReactNode;
}

function QridToast({ children, ...props }: ToastProps) {
  const content =
    typeof children === "string" ? <Text>{children}</Text> : children;
  return <DefaultToast {...props}>{content}</DefaultToast>;
}

export default function ToastContainer(props: Props) {
  return (
    <ToastProvider components={{ Toast: QridToast }}>
      {props.children}
    </ToastProvider>
  );
}
