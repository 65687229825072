import { createModel } from "@rematch/core";
import { OtpRequestTimerPayload } from "api-hooks/auth/auth.model";
import produce from "immer";
import { RematchDispatch, RootState } from "models";
import { Dispatch } from "redux";

export interface OtpState {
  otpRequestTimer?: object;
}

const initialState: OtpState = {
  otpRequestTimer: {},
};

const otp = createModel()({
  name: "otp",
  state: initialState,
  reducers: {
    setOtpRequestTimer(state: OtpState, payload: OtpRequestTimerPayload) {
      return produce(state, (draft) => {
        let otpTemp = state.otpRequestTimer || {};
        otpTemp = { ...otpTemp, [payload.phone]: payload.expiredTime };
        draft.otpRequestTimer = otpTemp;
      });
    },
  },
});

const otpRequestTimer = (state: RootState) => state.otp.otpRequestTimer;

export const otpSelector = {
  otpRequestTimer,
};

export const otpDispatcher = (dispatch: Dispatch) => {
  const otpDispatch = dispatch as RematchDispatch;
  return {
    setOtpRequestTimer: (payload: OtpRequestTimerPayload) => {
      return otpDispatch.otp.setOtpRequestTimer(payload);
    },
  };
};

export default otp;
