import { TextDocumentIcon, PDFIcon } from "@fluentui/react-icons-mdl2";
import { useGetUploadFileParam } from "api-hooks/upload/upload.mutation";
import { SpinnerSize, Text } from "components/elements";
import { decamelize } from "humps";
import * as React from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

import {
  DropzoneContainer,
  EditComponent,
  ImagePreview,
  ImagePreviewContainer,
  PlusText,
  PreviewContainer,
  RemoveImage,
  StyledSpinner,
} from "./style";

export interface FilePickerOptions {
  id?: string;
  name?: string;
  disabled?: boolean;
  value?: string;
  preview?: string;
  onPicked: (filename: string, fullUrl: string) => void;
  onDelete?: () => void;
  accept?: string;
  isError?: boolean;
}

export default function ImagePicker(props: FilePickerOptions) {
  const { value, preview, onDelete, onPicked, disabled, isError } = props;
  const { t } = useTranslation();
  const [isUploading, setIsUploading] = React.useState(false);
  const { mutateAsync: uploadFileParam } = useGetUploadFileParam();

  const onDrop = React.useCallback(
    async (acceptedFiles: File[]) => {
      try {
        const fileToUpload = acceptedFiles[0];
        setIsUploading(true);
        const result: any = await uploadFileParam({
          contentType: fileToUpload.type,
        });
        if (!result) {
          throw new Error("Data is undefined");
        }
        const inputs = result.inputs;
        const filename = inputs.key;

        const formData = new FormData();
        Object.keys(inputs).forEach((key) => {
          formData.append(decamelize(key, { separator: "-" }), inputs[key]);
        });
        formData.append("Content-Type", fileToUpload.type);
        formData.append("file", fileToUpload);

        const { action, method } = result.attributes;

        const uploadResponse = await fetch(action, {
          method,
          body: formData,
        });
        const uploadResponseBody = await uploadResponse.text();
        if (!uploadResponse.ok) {
          throw new Error(uploadResponseBody);
        }

        onPicked(result.fileName, `${result.attributes.action}/${filename}`);
      } catch (e) {
      } finally {
        setIsUploading(false);
      }
    },
    [onPicked, uploadFileParam]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const hasValue = !!value && !!preview;

  const { onClick: openFilePicker, ...rootProps } = getRootProps({
    refKey: "innerRef",
  } as any);

  const onRootClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (hasValue) {
        window.open(preview);
      } else {
        openFilePicker && openFilePicker(event);
      }
    },
    [hasValue, openFilePicker, preview]
  );

  return (
    <DropzoneContainer
      value={hasValue ? "true" : undefined}
      error={isError ? "true" : undefined}
      {...rootProps}
      onClick={onRootClick}
    >
      {!!preview && !!value && (
        <>
          <Preview url={preview} />
          {!disabled && (
            <>
              <RemoveImage
                onClick={(e) => {
                  !!onDelete && onDelete();
                  e.stopPropagation();
                }}
              >
                +
              </RemoveImage>
              <EditComponent
                onClick={(e) => {
                  openFilePicker && openFilePicker(e);
                  e.stopPropagation();
                }}
              >
                <Text variant="tiny">{t("common.edit")}</Text>
              </EditComponent>
            </>
          )}
        </>
      )}
      {isUploading ? (
        <StyledSpinner size={SpinnerSize.small} />
      ) : (
        <>
          <PlusText color={isError ? "error" : undefined}>+</PlusText>
          <input
            {...getInputProps()}
            id={props.id}
            name={props.name}
            accept={props.accept}
            disabled={disabled}
          />
        </>
      )}
    </DropzoneContainer>
  );
}

function Preview({ url }: { url: string }) {
  const splits = url.split("?")[0].split(".");
  const extension = splits[splits.length - 1].toLowerCase();
  if (["jpeg", "jpg", "png"].includes(extension)) {
    return (
      <ImagePreviewContainer>
        <ImagePreview src={url} alt={extension} />
      </ImagePreviewContainer>
    );
  }
  if (["pdf", "xlsx", "docx", "doc", "xls"].includes(extension)) {
    const Icon = (extension) => {
      switch (extension.extension) {
        case "xls":
        case "xlsx":
          return <TextDocumentIcon />;
        case "doc":
        case "docx":
          return <TextDocumentIcon />;
        case "pdf":
          return <PDFIcon />;
        default:
          return <TextDocumentIcon />;
      }
    };
    return (
      <PreviewContainer>
        <Icon extension={extension} />
      </PreviewContainer>
    );
  }
  return (
    <PreviewContainer>
      <Text variant="mediumPlus">{extension.toUpperCase()}</Text>
    </PreviewContainer>
  );
}
