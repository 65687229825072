import {
  TextField as RawTextField,
  Stack as RawStack,
  IStackProps,
  ITextFieldProps,
  IDatePickerProps,
  DatePicker as RawDatePicker,
  DayOfWeek,
  IDropdownProps,
  Dropdown,
  ICheckboxProps,
  Checkbox,
  IDatePickerStrings,
  IButtonProps,
  DefaultButton,
  Spinner,
  SpinnerSize,
  getTheme,
  CommandBar as RawCommandBar,
  ICommandBarProps,
  Label as RawLabel,
  ILabelProps,
  ISwatchColorPickerProps,
  SwatchColorPicker,
  IColorCellProps,
  PrimaryButton as RawPrimaryButton,
} from "@fluentui/react";
import { useId } from "@uifabric/react-hooks";
import classnames from "classnames";
import { css } from "common/utils/stitches.config";
import { format } from "date-fns";
import produce from "immer";
import { isEmpty } from "lodash";
import * as React from "react";
import { useController, useFormContext } from "react-hook-form";
import { defaultProps } from "recompose";
import { isArray } from "util";
import FilePicker from "./file-picker";
import { FormContext } from "./form";
export { FormState, default as Form } from "./form";

const ACCEPTED_FILE =
  "application/pdf,image/*,text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessing";

export {
  DefaultButton,
  Nav,
  Text,
  CommandButton,
  IconButton,
  Pivot,
  PivotItem,
  DetailsList,
  DetailsListLayoutMode,
  Dropdown,
  DayOfWeek,
  ComboBox,
  Checkbox,
  ChoiceGroup,
  ActionButton,
  ScrollablePane,
  ScrollbarVisibility,
  Sticky,
  StickyPositionType,
  Spinner,
  SpinnerSize,
  TooltipHost,
  MessageBar,
  MessageBarType,
  Modal,
  ContextualMenu,
  ShimmeredDetailsList,
  ShimmerElementsGroup,
  ShimmerElementType,
  Shimmer,
  TextField,
  Icon,
  Callout,
  Panel,
  Breadcrumb,
  ColorPicker,
} from "@fluentui/react";
export type {
  INavLinkGroup,
  INavLink,
  IButtonProps,
  IStackItemProps,
  IStyleFunctionOrObject,
  INavStyleProps,
  INavStyles,
  IIconProps,
  IComboBox,
  IComboBoxOption,
  IComboBoxProps,
  IComboBoxOptionStyles,
  IDropdownProps,
  IDropdownOption,
  IDragOptions,
  IColumn,
  ICommandBarItemProps,
  SearchBox,
  IDetailsItemProps,
  IDetailsListProps,
} from "@fluentui/react";

export * from "@uifabric/react-cards";

export function getColorPallete() {
  return getTheme().palette;
}

const DateFormat = "dd MMMM yyyy";

type InputType =
  | "text"
  | "tel"
  | "email"
  | "password"
  | "date"
  | "files"
  | "file"
  | "select"
  | "number"
  | "checkbox"
  | "dropdown"
  | "submit"
  | "color";

export interface InputProps {
  type: InputType;
  name: string;
}

interface TextInputProps extends InputProps, Omit<ITextFieldProps, "name"> {
  type: "text" | "password" | "number" | "email" | "tel";
  noErrorText?: boolean;
  readOnly?: boolean;
}

interface DatePickerProps
  extends InputProps,
    Omit<IDatePickerProps, "name" | "onSelectDate" | "value"> {
  type: "date";
}

interface FilesPickerProps extends InputProps {
  type: "files";
  label?: string;
  valueKey: string;
  previewKey: string;
  disabled?: boolean;
  noErrorText?: boolean;
}

interface FilePickerProps extends InputProps {
  type: "file";
  label?: string;
  previewName: string;
  disabled?: boolean;
}
interface CheckBoxProps
  extends InputProps,
    Omit<ICheckboxProps, "name" | "onChange" | "value" | "type"> {
  type: "checkbox";
}

interface DropDownProps
  extends InputProps,
    Omit<IDropdownProps, "name" | "onChange" | "value"> {
  type: "dropdown";
}

interface SubmitProps extends IButtonProps {
  type: "submit";
}

interface ColorProps extends InputProps, Omit<ISwatchColorPickerProps, "name"> {
  type: "color";
  disabled?: boolean;
  colorCells: IColorCellProps[];
  columnCount: number;
  label?: string;
}

export function Input(
  props:
    | TextInputProps
    | DatePickerProps
    | FilesPickerProps
    | FilePickerProps
    | CheckBoxProps
    | SubmitProps
    | DropDownProps
    | ColorProps
) {
  switch (props.type) {
    case "dropdown":
      return <DropdownField {...props} />;
    case "checkbox":
      return <CheckboxField {...props} />;
    case "date":
      return <DatePickerField {...props} />;
    case "file":
      return <FilePickerField {...props} />;
    case "files":
      return <FilesPickerField {...props} />;
    case "submit":
      return <SubmitField {...props} />;
    case "color":
      return <ColorPickerField {...props} />;
    default:
      return <TextField {...props} />;
  }
}

export function DatePicker(props: IDatePickerProps) {
  // HARDCODE INDONESIAN LANGUAGE
  const DayPickerStrings: IDatePickerStrings = {
    months: [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ],

    shortMonths: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Mei",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Des",
    ],

    days: ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"],

    shortDays: ["M", "S", "S", "R", "K", "J", "S"],

    goToToday: "Pergi ke hari ini",
    prevMonthAriaLabel: "Bulan sebelumnya",
    nextMonthAriaLabel: "Bulan selanjutnya",
    prevYearAriaLabel: "Tahun sebelumnya",
    nextYearAriaLabel: "Tahun selanjutnya",
  };

  const { formatDate, ...rest } = props;

  const dateFormatter = React.useCallback(
    (date?: Date) => (date ? format(date, DateFormat) : ""),
    []
  );

  return (
    <RawDatePicker
      {...rest}
      formatDate={formatDate || dateFormatter}
      strings={DayPickerStrings}
    />
  );
}

export function ReadonlyText({
  value,
  label,
  multiline,
}: {
  value?: string;
  label?: string;
  multiline?: boolean;
}) {
  const customValue = value ? value : typeof value === "number" ? value : "-";
  return (
    <RawTextField
      type="text"
      readOnly
      label={label}
      value={customValue}
      borderless
      multiline={multiline}
      styles={{
        field: {
          padding: 0,
          fontWeight: "bold",
        },
        subComponentStyles: {
          label: {
            root: [
              {
                color: getColorPallete().neutralSecondaryAlt,
              },
            ],
          },
        },
      }}
    />
  );
}

function TextField(props: TextInputProps) {
  const { control } = useFormContext();
  const { field, fieldState, formState } = useController({
    name: props.name,
    control,
  });
  const context = React.useContext(FormContext);
  const { noErrorText, readOnly } = props;

  const _onChange = React.useCallback(
    (e, val) => {
      if (props.type === "number") {
        const customValue = val ? parseFloat(val) : undefined;
        field.onChange(customValue);
      } else {
        field.onChange(e);
      }
    },
    [field, props.type]
  );

  const _error = fieldState?.error?.message;

  if (!context.editable || readOnly) {
    return (
      <ReadonlyText
        value={
          props.type === "password"
            ? Array(field.value?.length).join("*")
            : field.value
        }
        label={props.label}
        multiline={props.multiline}
      />
    );
  }

  return (
    <RawTextField
      {...field}
      {...props}
      onChange={_onChange}
      styles={{
        subComponentStyles: {
          label: {
            backgroundColor: "black",
          },
        },
      }}
      errorMessage={fieldState.isTouched && !noErrorText ? _error : undefined}
    />
  );
}

function SubmitField(props: SubmitProps) {
  const { disabled, primary = true, ...restProps } = props;
  const context = React.useContext(FormContext);
  const { formState } = useFormContext();

  if (!context.editable && !formState.isSubmitting) {
    return null;
  }
  const isValid = isEmpty(formState.errors);

  return (
    <DefaultButton
      {...restProps}
      onRenderIcon={
        formState.isSubmitting
          ? () => <Spinner size={SpinnerSize.medium} />
          : undefined
      }
      type="submit"
      disabled={disabled || !isValid}
      primary={primary}
    />
  );
}

function ColorPickerField(props: ColorProps) {
  const { control } = useFormContext();
  const { field, fieldState, formState } = useController({
    name: props.name,
    control,
  });
  const context = React.useContext(FormContext);

  const { colorCells, columnCount, label, ...colorProps } = props;
  const [previewColor, setPreviewColor] = React.useState<string>();

  const _error = fieldState?.error?.message;

  const swatchColorPickerOnCellHovered = React.useCallback(
    (id?: string, color?: string) => {
      setPreviewColor(color!);
    },
    []
  );

  // if (!context.editable) {
  //   return (
  //     <SwatchColorPicker
  //       colorCells={colorCells}
  //       columnCount={columnCount}
  //       selectedId={fields.value}
  //       onColorChanged={(id, color) => helpers.setValue(color)}
  //       disabled
  //       {...colorProps}
  //     />
  //   );
  // }

  return (
    <Stack>
      <Label>{label}</Label>
      <SwatchColorPicker
        onCellHovered={swatchColorPickerOnCellHovered}
        onCellFocused={swatchColorPickerOnCellHovered}
        colorCells={colorCells}
        columnCount={columnCount}
        selectedId={field.value}
        onColorChanged={(id, color) => field.onChange(color)}
        disabled={!context.editable || props.disabled}
        {...colorProps}
      />
      {_error && <span className={styles.errorMessage()}>{_error}</span>}
    </Stack>
  );
}

function DatePickerField(props: DatePickerProps) {
  const { control } = useFormContext();
  const { field, fieldState, formState } = useController({
    name: props.name,
    control,
  });

  const context = React.useContext(FormContext);
  const { onChange: dateTimeOnChange, onBlur, ...dateTimeRestFields } = field;

  const text = React.useMemo(() => {
    if (field.value instanceof Date) {
      return format(field.value, DateFormat);
    }
    return field.value;
  }, [field.value]);

  if (!context.editable) {
    return <ReadonlyText value={text} label={props.label} />;
  }

  const _error = fieldState?.error?.message;

  return (
    <DatePicker
      {...props}
      {...dateTimeRestFields}
      textField={{
        errorMessage: fieldState.isTouched ? _error : undefined,
      }}
      onAfterMenuDismiss={() => {
        setTimeout(() => {
          field.onBlur();
        });
      }}
      onSelectDate={field.onChange}
      firstDayOfWeek={DayOfWeek.Sunday}
      disabled={props.disabled}
    />
  );
}

function DropdownField(props: DropDownProps) {
  const { control } = useFormContext();
  const { field, fieldState, formState } = useController({
    name: props.name,
    control,
  });

  const context = React.useContext(FormContext);
  const { value, ...restFields } = field;

  const text = React.useMemo(() => {
    if (props.multiSelect) {
      let currentString = "";
      props.options?.forEach((v) => {
        if ((value || []).includes(v.key)) {
          currentString += `${v.text}, `;
        }
      });

      if (currentString.length > 1) {
        currentString = currentString.substr(0, currentString.length - 2);
      }

      return currentString;
    } else {
      return props.options.find((v) => v.key === value)?.text;
    }
  }, [props.multiSelect, props.options, value]);

  const _onChange = React.useCallback(
    (_, selected) => {
      if (props.multiSelect) {
        const currentValue = selected && selected.key;
        const isSelected = selected?.selected;

        field.onChange(
          isSelected
            ? [...(field.value || []), currentValue as string]
            : (field.value || []).filter((cur) => cur !== currentValue)
        );
      } else {
        field.onChange(selected && selected.key);
      }
    },
    [field, props.multiSelect]
  );

  const _error = fieldState?.error?.message;

  if (!context.editable) {
    return <ReadonlyText value={text} label={props.label} />;
  }

  return (
    <Dropdown
      {...restFields}
      {...props}
      selectedKeys={value}
      selectedKey={value}
      onChange={_onChange}
      errorMessage={fieldState.isTouched ? _error : undefined}
    />
  );
}

function CheckboxField(props: CheckBoxProps) {
  const { control } = useFormContext();
  const { field, fieldState, formState } = useController({
    name: props.name,
    control,
  });

  const { type, ...restCheckboxPox } = props;

  const context = React.useContext(FormContext);

  return (
    <Checkbox
      {...restCheckboxPox}
      onBlur={field.onBlur}
      checked={field.value}
      // eslint-disable-next-line handle-callback-err
      onChange={(e, checked) => {
        field.onChange(checked);
      }}
      disabled={props.disabled || !context.editable}
    />
  );
}

function FilePickerField(props: FilePickerProps) {
  const { control } = useFormContext();
  const {
    field: valueField,
    fieldState: valueFieldState,
    formState: valueFormState,
  } = useController({
    name: props.name,
    control,
  });
  const {
    field: previewField,
    fieldState: previewFieldState,
    formState: previewFormState,
  } = useController({
    name: props.previewName,
    control,
  });

  const context = React.useContext(FormContext);

  const disabled = props.disabled || !context.editable;

  const id = useId(props.name);
  const onPicked = React.useCallback(
    (file: string, fileUrl: string) => {
      valueField.onChange(file);
      previewField.onChange(fileUrl);
    },
    [valueField, previewField]
  );
  const onRemove = React.useCallback(() => {
    valueField.onChange(undefined);
    previewField.onChange(undefined);
  }, [valueField, previewField]);

  const _error = isEmpty(valueFieldState?.error?.message);
  const errorContent = React.useMemo(() => {
    if (!!_error && isArray(_error)) {
      return (
        <>
          {_error.map((currentError, idx) => {
            return (
              <>
                {currentError.file && (
                  <li>{`Item #${idx + 1}: ${currentError.file}`}</li>
                )}
                {currentError.fileUrl && (
                  <li>{`Item #${idx + 1}: ${currentError.fileUrl}`}</li>
                )}
              </>
            );
          })}
        </>
      );
    } else if (_error) {
      return <li>{_error}</li>;
    }

    return null;
  }, [_error]);

  if (!context.editable && !valueField.value && !previewField.value) {
    return <ReadonlyText value="-" label={props.label} />;
  }

  return (
    <Stack tokens={{ childrenGap: 4 }}>
      {props.label && (
        <Label
          htmlFor={id}
          style={{
            color: !context.editable
              ? getColorPallete().neutralSecondaryAlt
              : undefined,
          }}
        >
          {props.label}
        </Label>
      )}
      <FilePicker
        id={id}
        name={props.name}
        onPicked={onPicked}
        disabled={disabled}
        onDelete={onRemove}
        value={valueField.value}
        preview={previewField.value}
        accept={ACCEPTED_FILE}
        isError={!!_error}
      />
      {errorContent && (
        <ul className={styles.errorListContainer()}>{errorContent}</ul>
      )}
    </Stack>
  );
}

export function Label(props: ILabelProps) {
  const context = React.useContext(FormContext);
  return (
    <RawLabel
      {...props}
      style={{
        color: !context.editable
          ? getColorPallete().neutralSecondaryAlt
          : undefined,
        ...props.style,
      }}
    />
  );
}

function FilesPickerField(props: FilesPickerProps) {
  const { control } = useFormContext();
  const { field, fieldState, formState } = useController({
    name: props.name,
    control,
  });
  const context = React.useContext(FormContext);
  const { valueKey, previewKey, noErrorText } = props;
  const disabled = props.disabled || !context.editable;
  const id = useId(props.name);

  const handleDeleteFile = React.useCallback(
    (index) => {
      const newFiles = produce(field.value, (draft) => {
        draft.splice(index, 1);
      });
      field.onChange(newFiles);
    },
    [field]
  );

  const handleAddOrUpdateFile = React.useCallback(
    ({ file, fileUrl }: { file: string; fileUrl: string }, index?: number) => {
      const newFiles = produce(field.value, (draft) => {
        if (index !== undefined) {
          draft[index][valueKey] = file;
          draft[index][previewKey] = fileUrl;
        } else {
          draft.push({
            [valueKey]: file,
            [previewKey]: fileUrl,
          });
        }
      });
      field.onChange(newFiles);
    },
    [field, previewKey, valueKey]
  );
  const _error = isEmpty(fieldState?.error?.message);

  const errorContent = React.useMemo(() => {
    if (Array.isArray(field.value) && !noErrorText) {
      if (!!_error && isArray(_error)) {
        return (
          <>
            {_error.map((currentError, idx) => {
              return (
                <>
                  {currentError.file && (
                    <li>{`Item #${idx + 1}: ${currentError.file}`}</li>
                  )}
                  {currentError.fileUrl && (
                    <li>{`Item #${idx + 1}: ${currentError.fileUrl}`}</li>
                  )}
                </>
              );
            })}
          </>
        );
      } else if (!!_error && !field.value.length) {
        return <li>{_error}</li>;
      }
    }

    return null;
  }, [_error, field.value, noErrorText]);

  if (Array.isArray(field.value)) {
    return (
      <Stack tokens={{ childrenGap: 4 }}>
        {props.label && (
          <Label
            htmlFor={id}
            style={{
              color: !context.editable
                ? getColorPallete().neutralSecondaryAlt
                : undefined,
            }}
          >
            {props.label}
          </Label>
        )}
        <Stack horizontal wrap>
          {field.value.map((item, idx) => {
            return (
              <FilePicker
                key={item[props.valueKey]}
                onPicked={(file, fileUrl) => {
                  handleAddOrUpdateFile({ file, fileUrl }, idx);
                }}
                disabled={disabled}
                onDelete={() => handleDeleteFile(idx)}
                value={item[props.valueKey]}
                preview={item[props.previewKey]}
                accept={ACCEPTED_FILE}
              />
            );
          })}
          {!disabled && (
            <FilePicker
              onPicked={(file, fileUrl) =>
                handleAddOrUpdateFile({ file, fileUrl })
              }
              accept={ACCEPTED_FILE}
              isError={!field.value.length && !!_error && !noErrorText}
            />
          )}
        </Stack>
        {errorContent && !noErrorText && (
          <ul className={styles.errorListContainer()}>{errorContent}</ul>
        )}
      </Stack>
    );
  }
  return null;
}

export function Stack(props: IStackProps) {
  const { className, ...restProps } = props;

  return (
    <RawStack
      {...restProps}
      tokens={{ childrenGap: 16, ...props.tokens }}
      className={classnames(
        styles.defaultStack().toString(),
        className?.toString()
      )}
    />
  );
}

export function PrimaryButton(props: IButtonProps) {
  const { className, ...restProps } = props;

  return <RawPrimaryButton {...restProps} className={className?.toString()} />;
}

Stack.Item = RawStack.Item;

const styles = {
  defaultStack: css({
    "& > .ms-TextField > .ms-TextField-wrapper > label": {
      paddingTop: 0,
    },
  }),
  errorMessage: css({
    fontSize: 12,
    fontWeight: 400,
    color: "rgb(164, 38, 44)",
    "@xxl": {
      fontSize: 14,
    },
  }),
  readOnlyText: css({
    border: "none !important",
  }),
  errorListContainer: css({
    color: "#d13438",
    padding: 0,
    fontSize: 14,
    marginBottom: 0,
    "@xxl": {
      fontSize: 16,
    },

    li: {
      marginBottom: "2px !important",
    },
  }),
};

const withStyle = defaultProps<ICommandBarProps>({
  items: [],
  styles: {
    root: { padding: 0, marginBottom: 16 },
  },
});

export const CommandBar = withStyle(RawCommandBar);
