import routes from "common/routes/routes";
import LoadingComponent from "components/widgets/loading-component";
import { AccountProvider } from "hooks/use-account";
import { DrawerProvider } from "hooks/use-drawer";
import { NotificationProvider } from "hooks/use-notification";
import { SettingProvider } from "hooks/use-setting";
import { authSelector } from "models/auth";
import Router, { useRouter } from "next/router";
import { useSession } from "next-auth/react";
import * as React from "react";
import { useSelector } from "react-redux";

export default function AuthLayout(props) {
  const router = useRouter();
  const { data: session, status } = useSession();
  const isLoginPath = router.pathname === "/user/login";
  const isProfilePath = router.pathname === "/user/profile";
  const me = useSelector(authSelector.userSelector);

  if (status === "loading" && !session) {
    return <LoadingComponent />;
  }

  if (!session && !isLoginPath) {
    return <Redirect to="/user/login" />;
  } else if (!session) {
    return props.children;
  } else if (!!session && !!me && !me?.name && !isProfilePath) {
    return <Redirect to={routes.profile} />;
  }
  return (
    <AccountProvider>
      <SettingProvider>
        <DrawerProvider>
          <NotificationProvider>{props.children}</NotificationProvider>
        </DrawerProvider>
      </SettingProvider>
    </AccountProvider>
  );
}
export function Redirect(props: { to: string }) {
  const { to } = props;
  React.useEffect(() => {
    Router.push(to);
  }, [to]);
  return null;
}
