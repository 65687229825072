import invariant from "invariant";

function required(name: string, value?: string): string {
  invariant(value !== undefined, `${name} is required on env vars`);
  return value!;
}

export default {
  apiEndpoint: required(
    "NEXT_PUBLIC_API_ENDPOINT",
    process.env.NEXT_PUBLIC_API_ENDPOINT
  ),
  sentryDSN: required(
    "NEXT_PUBLIC_SENTRY_DSN",
    process.env.NEXT_PUBLIC_SENTRY_DSN
  ),
  firebaseApiKey: required(
    "NEXT_PUBLIC_FIREBASE_API_KEY",
    process.env.NEXT_PUBLIC_FIREBASE_API_KEY
  ),
  firebaseAuthDomain: required(
    "NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN",
    process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN
  ),
  firebaseProjectId: required(
    "NEXT_PUBLIC_FIREBASE_PROJECT_ID",
    process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID
  ),
  firebaseStorageBucket: required(
    "NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET",
    process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET
  ),
  firebaseMessagingSenderId: required(
    "NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID",
    process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID
  ),
  firebaseAppId: required(
    "NEXT_PUBLIC_FIREBASE_APP_ID",
    process.env.NEXT_PUBLIC_FIREBASE_APP_ID
  ),
  firebaseMeasurementId: required(
    "NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID",
    process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID
  ),
  cloudflareSiteKey: required(
    "NEXT_PUBLIC_CLOUDFLARE_SITE_KEY",
    process.env.NEXT_PUBLIC_CLOUDFLARE_SITE_KEY
  ),
};
