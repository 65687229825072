export default {
  settings: "Pengaturan",
  application: "Aplikasi",
  report_download: "Unduh Laporan",
  consumer_setting: "Atur Pemakai",
  location_setting: "Atur Lokasi",
  notification_setting: "Atur Notifikasi",
  user_authority: "Otoritas Pengguna",
  change_pin: "Ubah PIN",
  change_phone_number: "Ubah Nomor Ponsel",
  delete_account: "Hapus Akun",
  information: "Informasi",
  tutorial: "Tutorial",
  text_us: "Chat Kami",
  website: "Website",
  instagram: "Instagram",
  rate_application: "Rating Aplikasi",
  term_and_condition: "Syarat & Ketentuan",
  privacy_policy: "Kebijakan Privasi",

  settings_notification: "Pengaturan Notifikasi",
  asset_notification: "Notifikasi Aset",
  asset_notification_desc: "Menerima notifikasi perubahan terhadap data set",
  service_notification: "Notifikasi Servis",
  service_notification_desc:
    "Menerima notifikasi perubahan data servis semua aset",
  request_notification: "Notifikasi Request",
  request_notification_desc:
    "Menerima notifikasi permintaan servis oleh member team",
  reminder_notification: "Notifikasi Reminder",
  reminder_notification_desc:
    "Menerima notifikasi jatuh tempo servis dan dokumen",
  time_notification: "Waktu Notifikasi",
  time_service_notification_desc:
    "Pengingat servis selanjutnya untuk aset milik anda",
  follow_up_notification: "Notifikasi Follow Up",
  time_follow_up_notification_desc:
    "Pengingat follow up servis aset milik pelanggan anda",

  notification_days_before: "{{day}} hari sebelumnya",
  notification_weeks_before: "{{week}} minggu sebelumnya",
  notification_months_before: "{{month}} bulan sebelumnya",

  choose_time_notification: "Pilih Waktu Notifikasi",

  record_service: "Catat Servis",
  submit_service: "Submit Servis",
  submit_service_desc: "Akun staf bisa submit servis",
  pending_service: "Servis Pending",
  pending_service_desc: "Akun staf bisa lihat servis pending akun staf lain",
  menu_access: "Akses Menu",
  asset_menu: "Menu Aset",
  asset_menu_desc: "Akun staf bisa akses menu Aset bisnis ini",
  history_menu: "Menu Riwayat",
  history_menu_desc: "Akun staf bisa akses menu Riwayat bisnis ini",
  reminder_menu: "Menu Reminder",
  reminder_menu_desc: "Akun staf bisa akses menu Reminder bisnis ini",
  customer_menu: "Menu Pelanggan",
  customer_menu_desc: "Akun staf bisa akses menu Pelanggan bisnis ini",
  data_between_staff: "Data Antar Staf",
  history_between_staff: "Riwayat Antar Staf",
  history_between_staff_desc:
    "Akun staf bisa lihat riwayat servis akun staf lain",
  reminder_between_staff: "Reminder Antar Staf",
  reminder_between_staff_desc:
    "Akun staf bisa lihat reminder servis akun staf lain",
  customer_between_staff: "Pelanggan Antar Staf",
  customer_between_staff_desc:
    "Akun staf bisa lihat daftar pelanggan akun staf lain",

  consumer_filter: "Filter Pemakai",
  add_consumer: "Tambah Pemakai",
  edit_consumer: "Edit Pemakai",
  group_by_filter: "Group by {{filter}}",
  all: "Semua",
  consumer: "Pemakai",
  renter: "Penyewa",
  alphabet: "Abjad",
  division: "Divisi",
  company: "Perusahaan",
  edit: "Edit",
  usage: "Pakai",
  rent: "Sewa",
  add_location: "Tambah Lokasi",
  edit_location: "Edit Lokasi",
  delete_location: "Hapus Lokasi",
  input_location_name: "Input Nama Lokasi",
  input_old_pin: "Masukkan PIN Lama",
  input_new_pin: "Masukkan PIN Baru",
  reinput_new_pin: "Masukkan Ulang PIN Baru",
  asset_report: "Laporan Aset",
  service_report: "Laporan Servis",
  xlsx: "XLSX",
  pdf: "PDF",
  pdf_xlsx: "PDF / XLSX",
  choose_export_file_type: "Pilih Tipe File Export",
  download_service_history: "Unduh Riwayat Servis",
  choose_all: "Pilih Semua",
  download: "Unduh",
  download_photo: "Unduh Foto",
  website_link: "https://qrid.co",
  link_privacy: "https://qrid.co/privacy",
  link_tnc: "https://qrid.co/tnc",
  link_chat: "whatsapp://send?&phone=+628111641188",
  link_not_supported_chat: "https://api.whatsapp.com/send?phone=628111641188",
  instagram_link: "instagram://user?username=qrid.co",
  instagram_not_supported_link: "https://instagram.com/qrid.co",
  input_pin: "Masukkan PIN",
  update_phone_number: "Update Nomor HP",
  delete_qrid_account: "Hapus Akun QRID",
  delete_qrid_account_desc:
    "Kami sungguh merasa kehilangan dengan penghapusan akunmu. Apakah kamu yakin ingin menghapus akunmu?",
  confirm_delete_account_OTP:
    "Masukkan kode OTP yang dikirimkan ke nomor ponsel kamu pada layar selanjutnya untuk konfirmasi penghapusan akunmu.",
  confirm_delete_account_desc:
    "Akun dan data yang sudah dihapus tidak dapat dipulihkan kembali. Silahkan lakukan pencadangan data sebelum melanjutkan.",
  confirm_delete_account_confirmation:
    "Kamu masih bisa batalkan niatmu dengan pilih tombol Batal bewarna putih di kiri bawah layar",
  export_file_successful: "Berhasil export file",
  download_file_successful: "Berhasil download file",

  choose_asset_type: "Pilih Jenis Aset",
  choose_period: "Pilih Periode",
  consumer_detail: "Detail Pemakai",
  location_detail: "Detail Lokasi",
};
