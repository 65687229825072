import common from "./common";
import error from "./error";
import home from "./home";
import information from "./information";
import itemDetail from "./item-detail";
import setting from "./setting";
import shops from "./shops";
import track from "./track";
export default {
  shops,
  error,
  common,
  itemDetail,
  information,
  home,
  track,
  setting,
};
