import i18n from "i18next";

import id from "./id";

i18n.init({
  resources: {
    id: {
      translation: {
        ...id,
      },
    },
  },
  fallbackLng: "id",
  lng: "id",
  interpolation: {
    escapeValue: false,
  },
  ns: ["translation"],
  defaultNS: "translation",
});

export default i18n;
