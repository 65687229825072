// eslint-disable-next-line import/order
/* eslint-disable */
import "react-responsive-tabs/styles.css";
import "flexboxgrid/css/flexboxgrid.css";
import { useEffect } from "react";
import { global } from "common/utils/stitches.config";
import { QueryClientProvider } from "react-query";
import Head from "next/head";
import ToastContainer from "containers/toast-container";
import i18n from "locales";
import { I18nextProvider } from "react-i18next";
import { queryClient } from "common/repositories";
import UserLayout from "containers/user-layout";
import DefaultLayout from "containers/default-layout";
import "../../styles/font-nunito.css";
import dynamic from "next/dynamic";
Date.prototype.toString = function dateToString(): string {
  const time = new Date(this.getTime());
  time.setHours(0, 0, 0, 0);
  return time.toISOString();
};

const HomeLayout = dynamic(() => import("../containers/home-layout"), {
  ssr: false,
});

function myApp({ Component, pageProps, router }) {
  let Layout = HomeLayout;

  if (router.pathname.startsWith("/user")) {
    Layout = UserLayout;
  }
  if (router.pathname.startsWith("/q") || router.pathname.startsWith("/r")) {
    Layout = DefaultLayout;
  }

  globalStyles();

  useEffect(() => {
    if (typeof window !== "undefined") {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
      window.addEventListener("resize", () => {
        // We execute the same script as before
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
      });
      window.addEventListener("beforeunload", function (e) {
        return;
        e.preventDefault();
        e.returnValue = "";
      });
    }
  }, []);

  return (
    <>
      <Head>
        <title>QRID</title>
        <link rel="icon" href="/assets/favicon.ico" />
      </Head>
      <I18nextProvider i18n={i18n}>
        <QueryClientProvider client={queryClient}>
          <ToastContainer>
            <Layout pageProps={pageProps}>
              <Component {...pageProps} />
            </Layout>
          </ToastContainer>
        </QueryClientProvider>
      </I18nextProvider>
    </>
  );
}

export default myApp;

const globalStyles = global({
  html: {
    fontFamily:
      '"Nunito","Inter", "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", "-apple-system", "BlinkMacSystemFont", "Roboto", "Helvetica Neue", "sans-serif"',
    fontSize: "16px",
    boxShadow: "none",
  },
  "*": {
    boxSizing: "border-box",
    scrollbarWidth: "thin",
    scrollbarColor: "darkgrey transparent",
    WebkitTapHighlightColor: "transparent !important",
    fontFamily: "Nunito",
  },
  "*::-webkit-scrollbar": {
    width: "4px",
    height: "4px",
  },
  "*::-webkit-scrollbar-track": {
    background: "none",
    borderRadius: "50%",
  },
  "*::-webkit-scrollbar-thumb": {
    backgroundColor: "darkgrey",
    borderRadius: "24px",
  },
  "*::before": {
    boxSizing: "border-box",
  },
  "*::after": {
    boxSizing: "border-box",
  },
  "ul[class]": {
    margin: 0,
    listStyle: "none",
  },
  "ol[class]": {
    margin: 0,
    listStyle: "none",
  },
  body: {
    maxWidth: "100vw",
    minHeight: "calc(var(--vh, 1vh) * 100)",
    scrollBehavior: "smooth",
    textRendering: "optimizeSpeed",
    lineHeight: "1.3",
    margin: 0,
    fontFamily: "Nunito",

    "> div#__next": {
      height: "calc(var(--vh, 1vh) * 100)",
    },
  },
  h1: {
    margin: 0,
  },
  h2: {
    margin: 0,
  },
  h3: {
    margin: 0,
  },
  h4: {
    margin: 0,
  },
  figure: {
    margin: 0,
  },
  blockquote: {
    margin: 0,
  },
  dl: {
    margin: 0,
  },
  dd: {
    margin: 0,
  },
  "a:not([class])": {
    textDecorationSkipInk: "auto",
  },
  img: {
    maxWidth: "100%",
    display: "block",
  },
  input: {
    font: "inherit",
  },
  button: {
    font: "inherit",
  },
  textarea: {
    font: "inherit",
  },
  select: {
    font: "inherit",
  },
  span: {
    fontFamily: "Nunito !important",
  },
  // "img:not([alt])": {
  //   filter: "blur(10px)",
  // },
  'input[type="number"]::-webkit-inner-spin-button': {
    cursor: "pointer",
    display: "block",
    width: "30px",
    height: "45px",
    textAlign: "center",
    position: "relative",
    background: "transparent",
  },
  'input[type="number"]::-webkit-inner-spin-button::before': {
    top: "7px",
    content: "",
    position: "absolute",
    right: 0,
    width: 0,
    height: 0,
    borderLeft: "7px solid transparent",
    borderRight: "7px solid transparent",
    borderBottom: "10px solid #777",
  },
  'input[type="number"]::-webkit-inner-spin-button::after': {
    bottom: "7px",
    transform: "rotate(180deg)",
    content: "",
    position: "absolute",
    right: 0,
    width: 0,
    height: 0,
    borderLeft: "7px solid transparent",
    borderRight: "7px solid transparent",
    borderBottom: "10px solid #777",
  },
  ".ms-TextField > .ms-TextField-wrapper > label": {
    paddingTop: "0 !important",
  },

  ".RRT__showmore": {
    borderTopLeftRadius: "8px !important",
    borderTopRightRadius: "8px !important",
  },

  ".RRT__showmore-label": {
    padding: "0px 16px !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "19px",
  },

  ".RRT__removable": {
    //@ts-expect-error
    position: "unset !important",
  },

  ".RRT__removable-text": {
    marginRight: "4px !important",
  },

  ".RRT__removable-icon": {
    position: "absolute",
    top: "8px !important",
    right: "6px !important",
    color: "transparent",
    width: "20px",
    fontSize: "19px !important",
    height: "20px",
    borderRadius: "12px",
    "::before": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontWeight: "bold",
      fontSize: "18px !important",
      content: "'+'",
      transform: "rotate(45deg)",
      position: "absolute",
      color: "#888 !important",
      top: 0,
      left: 0,
      right: "-2px",
      bottom: 0,
    },
  },
  ".pagination": {
    padding: 0,
  },

  ".pagination > li": {
    display: "inline-block",
    paddingLeft: 0,
    listStyle: "none",
  },

  ".pagination > li > a, .pagination > li > span": {
    position: "relative",
    float: "left",
    padding: "6px 12px",
    border: "1px solid #e2e2e2",
    borderRadius: "4px",
    outline: "none",
    margin: "0 2px",
    WebkitTouchCallout: "none",
    WebkitUserSelect: "none",
    MozUserSelect: "none",
    msUserSelect: "none",
    userSelect: "none",
  },
  ".pagination > li > a:hover, .pagination > li > span:hover": {
    cursor: "pointer",
  },

  ".pagination > li.active > a": {
    color: "#fff",
    backgroundColor: "#0078d4",
    borderColor: "#0078d4",
  },

  ".pagination > li > a:hover": {
    backgroundColor: "#0078d4",
    color: "white",
  },

  ".pagination-disabled": {
    opacity: 0.8,
  },
  ".pagination-disabled: hover": {
    cursor: "not-allowed",
  },

  ".ms-DatePicker-goToday": {
    right: "13px",
  },

  ".ck-editor__editable": {
    minHeight: 300,
  },
  ".ms-Icon": {
    color: "#000000 !important",
  },
  "thead  .ant-table-cell": {
    backgroundColor: "white !important",
  },
  "tbody .ant-table-cell:hover": {
    cursor: "pointer",
  },
  ".ant-table-cell::before": {
    width: "0px !important",
  },
  ".ant-pagination-item": {
    backgroundColor: "#fff !important",
    borderColor: "#E0E0E0 !important",
    fontWeight: "700 !important",
    fontSize: "14px !important",
    fontFamily: "Nunito !important",
  },
  ".ant-pagination-item > a": {
    color: "#828282 !important",
  },
  ".ant-pagination-item-active": {
    backgroundColor: "#2F80ED !important",
    borderColor: "#E0E0E0 !important",
    fontWeight: "700 !important",
    fontSize: "14px !important",
    fontFamily: "Nunito !important",
  },
  ".ant-pagination-item-active > a": {
    color: "#fff !important",
  },
  "@keyframes shimmer": {
    "0%": {
      backgroundPosition: "-1200px 0",
    },
    "100%": {
      backgroundPosition: "1200px 0",
    },
  },
  ".ant-checkbox-checked  .ant-checkbox-inner": {
    backgroundColor: "#27AE60 !important",
    borderColor: "#27AE60 !important",
  },
  ".ant-checkbox-wrapper:hover .ant-checkbox-inner": {
    borderColor: "#27AE60 !important",
  },
  ".ant-checkbox:hover .ant-checkbox-inner": {
    borderColor: "#27AE60 !important",
  },
  ".ant-checkbox-input:focus .ant-checkbox-inner": {
    borderColor: "#27AE60 !important",
  },
  ".ant-checkbox-indeterminate .ant-checkbox-inner::after": {
    borderColor: "#27AE60 !important",
  },
  ".ant-select-selector": {
    fontFamily: "Nunito !important",
  },
  ".ant-drawer-body": {
    padding: "0px !important",
  },
  ".customTable .ant-table-tbody>tr>td": {
    padding: "0px !important",
  },

  ".customTable .ant-table-thead>tr>th, .customTable .ant-table-thead>tr>td": {
    padding: "12px 16px !important",
    whiteSpace: "nowrap",
    backgroundColor: "#F8F8F8 !important",
    color: "#000 !important",
    fontWeight: "700 !important",
    fontFamily: "Nunito !important",
  },
  ".customTable .ant-spin-nested-loading": {
    height: "calc(100% - 47px)",
  },
  ".customTable .ant-spin-container, .customTable .ant-table-fixed-header, .customTable .ant-table-container ":
    {
      height: "100%",
    },
  ".customTable .ant-table-cell-scrollbar": {
    backgroundColor: "#F8F8F8 !important",
  },
  ".ant-table-thead >tr>th": {
    backgroundColor: "#F8F8F8 !important",
    color: "#000 !important",
    fontWeight: "700 !important",
    fontFamily: "Nunito !important",
  },
  ".insight-table .ant-table-thead>tr>th": {
    backgroundColor: "#F8F8F8 !important",
    color: "#828282 !important",
  },
  ".insight-table .ant-table-thead > tr > th:last-child": {
    textAlign: "right",
  },
  ".insight-table .ant-table-tbody>tr>td": {
    cursor: "default",
  },
  ".insight-table .ant-table-tbody>tr:last-child": {
    backgroundColor: "transparent !important",
    color: "#828282 !important",
  },
  ".insight-table .ant-table-tbody>tr:last-child>td": {
    backgroundColor: "#F8F8F8 !important",
    color: "#828282 !important",
  },
  ".insight-table .ant-table-tbody>tr:last-child>td:first-child": {
    borderBottomLeftRadius: 12,
  },
  ".insight-table .ant-table-tbody>tr:last-child>td:last-child": {
    borderBottomRightRadius: 12,
  },
  ".ant-modal-content": {
    borderRadius: "20px !important",
  },
});
