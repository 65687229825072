import invariant from "invariant";
import * as React from "react";

interface DrawerProviderProps {
  children: React.ReactNode;
}
interface ContextProps {
  drawer: boolean;
  setDrawer: (value: boolean) => void;
}

const drawerContext = React.createContext<ContextProps>({
  drawer: false,
  setDrawer: () => {},
});

export function DrawerProvider(props: DrawerProviderProps) {
  const [drawer, setDrawer] = React.useState<boolean>(false);
  return (
    <drawerContext.Provider value={{ drawer, setDrawer }}>
      {props.children}
    </drawerContext.Provider>
  );
}
export function useDrawer() {
  const context = React.useContext(drawerContext);
  invariant(
    context !== undefined,
    "useDrawer must be used insied drawerContext.Provider"
  );
  return context;
}
