export async function blobToBase64(blob: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      resolve(reader.result as string);
    };
    reader.onerror = function () {
      reject(reader.error);
    };
  });
}

export function debugBase64(base64URL) {
  const win = window.open();
  win?.document.write(
    `
      <title>Print Label</title>
      <body style="margin:0;">
      <iframe src="
        ${base64URL}
        " frameborder="0" style="margin:0; border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allow="fullscreen"></iframe>
      </body>
      `
  );
}
