import { Spin } from "antd";
import LottiePlayer from "components/common/lottie-player";
import * as React from "react";

import * as LoadingAnimation from "../../../public/assets/animations/paper-plane.json";
import { css } from "../../common/utils/stitches.config";
export default function LoadingComponent() {
  return (
    <div className={styles.container()}>
      <LottiePlayer
        animationData={LoadingAnimation}
        style={{ width: 300, height: 300 }}
      />
    </div>
  );
}
const styles = {
  container: css({
    position: "fixed",
    width: "100%",
    height: "calc(var(--vh, 1vh) * 100)",
    backgroundColor: "rgba(255,255,255,0.15)",
    backdropFilter: "blur(5px)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
    left: 0,
    zIndex: 999,
  }),
};
