import { Spinner, Stack } from "@fluentui/react";
import { styled } from "common/utils/stitches.config";

export const PlusText = styled("span", {
  userSelect: "none",
  margin: "0 !important",

  variants: {
    color: {
      error: {
        color: "$db6262",
      },
    },
  },
});

export const PreviewContainer = styled("div", {
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "grey",
  color: "white",
});

export const DropzoneContainer = styled(Stack, {
  position: "relative",
  display: "flex",
  fontSize: 30,
  color: "grey",
  width: 50,
  height: 50,
  border: "1px grey dashed",
  borderRadius: 5,
  alignItems: "center",
  justifyContent: "center",
  "&:hover": {
    cursor: "pointer",
  },

  variants: {
    value: {
      true: {
        "&:hover:after": {
          position: "absolute",
          top: 0,
          left: 0,
          bototm: 0,
          right: 0,
          content: " ",
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          zIndex: 1,
        },
      },
    },
    error: {
      true: {
        border: "1px #d13438 dashed !important",
      },
    },
  },
});

export const ImagePreview = styled("img", {
  height: "100%",
  width: "100%",
  objectFit: "cover",
});

export const ImagePreviewContainer = styled("div", {
  backgroundColor: "white",
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
});

export const RemoveImage = styled("div", {
  position: "absolute",
  top: -6,
  right: -6,
  paddingBottom: 1,
  backgroundColor: "#e75b64",
  color: "white",
  borderRadius: "50%",
  fontSize: 16,
  width: 17,
  height: 16,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transform: "rotate(45deg)",
  userSelect: "none",
  margin: "0 !important",
  zIndex: 1000,
  "&:hover": {
    cursor: "pointer",
  },
});

export const StyledSpinner = styled(Spinner, {
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  margin: "0px 0px !important",
});

export const EditComponent = styled("div", {
  backgroundColor: "rgba(0, 0, 0, 0.3)",
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
  padding: "2px 2px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  zIndex: 2,
  "&:hover": {
    span: {
      transform: "scale(1.1, 1.1)",
    },
  },
  span: {
    fontWeight: "bold !important",
    transition: "transform 200ms ease-in-out",
  },
});
