import { setupToken } from "common/repositories";
import { authDispatcher } from "models/auth";
import { getSession } from "next-auth/react";
import * as React from "react";
import { useDispatch } from "react-redux";

interface Props {
  children: React.ReactNode;
}
export default function PublicLayout(props: Props) {
  const [isTokenSet, setIsTokenSet] = React.useState(false);
  const dispatch = useDispatch();
  const { setCredentials } = authDispatcher(dispatch);
  React.useEffect(() => {
    async function exec() {
      const session: any = await getSession();
      const accessToken = session?.user?.accessToken;
      if (accessToken && session?.user?.error !== "RefreshAccessTokenError") {
        setCredentials(session?.user);
        await setupToken(accessToken);
      }
      setIsTokenSet(true);
    }

    exec();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return isTokenSet ? <>{props.children} </> : null;
}
