import { store } from "models";
import { SessionProvider } from "next-auth/react";
import AuthLayout from "pages/api/auth/auth-layout";
import * as React from "react";
import * as Redux from "react-redux";

import AppContainer from "./app-container";

interface DashboardLayoutProps {
  children: React.ReactNode;
  pageProps: any;
}

export default function UserLayout(props: DashboardLayoutProps) {
  const { session } = props.pageProps;

  return (
    <>
      <SessionProvider session={session} basePath="/api/auth">
        <Redux.Provider store={store}>
          <AppContainer>
            <AuthLayout>{props.children}</AuthLayout>
          </AppContainer>
        </Redux.Provider>
      </SessionProvider>
    </>
  );
}
