import { initializeIcons } from "@uifabric/icons";
import { client, setupOnUnAuthorized, setupToken } from "common/repositories";
import LoadingComponent from "components/widgets/loading-component";
import { authDispatcher } from "models/auth";
import { getSession, signOut } from "next-auth/react";
import * as React from "react";
import { useDispatch } from "react-redux";
import { setLocale } from "yup";

import yupIdValidation from "../locales/id/validation.yup";

setLocale(yupIdValidation);

initializeIcons(undefined, { disableWarnings: true });

interface Props {
  children: React.ReactNode;
}

export async function logout() {
  localStorage.clear();
  await signOut();
}

function Handler({ children }) {
  const [isTokenSet, setIsTokenSet] = React.useState(false);
  const [currentSession, setCurrentSession] = React.useState<any>();
  const dispatch = useDispatch();
  const { getMe, setCredentials } = authDispatcher(dispatch);

  React.useEffect(() => {
    async function exec() {
      const session: any = await getSession();
      const accessToken = session?.user?.accessToken;
      setupOnUnAuthorized(async (request, option, response) => {
        if (response.status === 401) {
          logout();
        }
        // if (response.status === 401 && !!request.headers.get("Authorization")) {
        //   try {
        //     const credentials = await getSession();
        //     await setupToken((credentials?.user as any)?.accessToken);
        //     return client(request);
        //   } catch (error) {
        //     logout();
        //   }
        // } else if (response.status === 401) {
        //   logout();
        // }
      });
      if (accessToken) {
        setCredentials(session?.user);
        await setupToken(accessToken);
        await getMe();
      }
      setCurrentSession(session);
      setIsTokenSet(true);
    }

    exec();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (currentSession?.user?.error === "RefreshAccessTokenError") {
      logout();
    }
  }, [currentSession]);

  return isTokenSet ? children : <LoadingComponent />;
}

export default function AppContainer(props: Props) {
  return <Handler>{props.children}</Handler>;
}
