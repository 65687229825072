import Config from "common/config";
import { blobToBase64 } from "common/helpers/string.helper";
import { camelizeKeys } from "humps";
import ky from "ky";
import { signOut } from "next-auth/react";
import qs from "qs";
import { QueryClient } from "react-query";

import { Filter, PaginationMeta, Sort } from "./common.model";
import toApiError from "./to-api-error";

export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };

export interface ApiError {
  message: string;
  type?: string;
  statusCode?: number;
  errors?: { [key: string]: string };
}

export interface ApiResult<T> {
  data: T;
  message?: string;
}
export interface MessageResult {
  message: string;
}
export interface ExtendedApiResult<T> extends ApiResult<T> {
  meta: PaginationMeta;
  filters: Filter[];
  sorts: Sort;
}

export interface CustomQueryOptions {
  queryKey: any[];
}

export interface CustomMutationOptions {
  mutationKey: any[];
}

const config = {
  prefixUrl: Config.apiEndpoint + "/api/user",
  timeout: 60000,
  headers: {
    Accept: "application/json",
    "Accept-Langugae": "id",
  },
  hooks: {
    afterResponse: [
      async (_request, _options, res) => {
        const contentType = res.headers.get("content-type");
        let newResponse = res.clone();
        if (contentType && contentType.includes("application/json")) {
          const json = camelizeKeys(await res.json());
          const { status, statusText, headers } = res;
          newResponse = new Response(JSON.stringify(json), {
            status,
            statusText,
            headers,
          });
        }

        if (
          contentType &&
          (contentType.includes("application/pdf") ||
            contentType.includes(
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ) ||
            contentType.includes("application/zip") ||
            contentType.includes("application/x-zip") ||
            contentType.includes("application/octet-stream"))
        ) {
          // newResponse = await blobToBase64(await res.blob());
          const { status, statusText, headers } = res;
          const blob = await res.blob();

          newResponse = new Response(blob, {
            status,
            statusText,
            headers,
          });
        }

        return newResponse;
      },
    ],
  },
};

export let client = ky.create(config);
export let downloadClient = ky.create({
  ...config,
  timeout: 10 * 60 * 1000,
});

export async function setupToken(token: string | null) {
  client = await client.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          if (token) {
            request.headers.set("Authorization", `Bearer ${token}`);
          }
        },
      ],
    },
  });
  downloadClient = await downloadClient.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          if (token) {
            request.headers.set("Authorization", `Bearer ${token}`);
          }
        },
      ],
    },
  });
}

export function setupOnUnAuthorized(
  func: (request: any, option: any, res: any) => void
) {
  if (config.hooks?.afterResponse) {
    config.hooks.afterResponse[1] = func as any;
  }
  client = client.extend(config);
}

export async function defaultQueryFn({
  queryKey,
}: CustomQueryOptions): Promise<any> {
  let params = "";

  if (queryKey[2]) {
    params = qs.stringify(queryKey[2]);
  }

  let currentClient = client;
  if (queryKey[3] === "download") {
    currentClient = downloadClient;
  }

  return new Promise(async (resolve, reject) => {
    try {
      const result = (await currentClient.get(queryKey[1], {
        searchParams: params,
      })) as any;
      const contentType = result.headers.get("content-type");

      let newResult = result;
      if (
        contentType !== "application/pdf" &&
        contentType !==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
        contentType !== "application/zip" &&
        contentType !== "application/x-zip" &&
        contentType !== "application/octet-stream"
      ) {
        newResult = await result.json();
      } else {
        newResult = await blobToBase64(await result.blob());
      }
      const transformedJson = {
        ...newResult,
      };

      resolve(
        contentType === "application/pdf" ||
          contentType ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          contentType === "application/zip" ||
          contentType === "application/x-zip" ||
          contentType === "application/octet-stream"
          ? newResult
          : transformedJson
      );
    } catch (e: any) {
      reject(await toApiError(e));
    }
  });
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      queryFn: defaultQueryFn as any,
      refetchOnWindowFocus: false,
    },
    mutations: {
      retry: false,
    },
  },
});
