import * as React from "react";
import Lottie from "react-lottie";

interface Props {
  animationData?: any;
  style?: React.CSSProperties;
}

export default function LottiePlayer(props: Props) {
  const { animationData, style } = props;

  const defaultOptions = React.useMemo(
    () => ({
      loop: true,
      autoplay: true,
      animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    }),
    [animationData]
  );
  return <Lottie options={defaultOptions} style={style} />;
}
