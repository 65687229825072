export default {
  detail_asset: "Detail Aset",
  qr_code: "QR Code",
  specification: "Spesifikasi",
  access_rights: "Hak Akses",
  edit: "Edit",
  add: "Add",
  pending: "Pending",
  grand_total: "Total Biaya",
};
