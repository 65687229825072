export default {
  phone_label: "Label No. Hp",
  type: "Tipe",
  number: "Nomor",
  phone: "Nomor",
  user: "User",
  item: "Item",
  role: "Role",
  add_user: "Tambah User",
  add_phone: "Tambah Nomor",
  add_item: "Tambah Item",
  is_active: "Aktif",

  mobile_type: "Mobile",
  office_type: "Kantor",
  whatsapp_type: "Whatsapp",

  address: "Alamat",
  address_label: "Label Alamat",
  street: "Jalan",
  city: "Kota",
  province: "Provinsi",
  country: "Negara",

  find_shop: "Cari Toko",
  create_new_shop: "Buat Toko Baru",
  select_shop: "Pilih Toko",
  setting: "Settings",
  is_shared_service_staff: "Staf bisa lihat servis pending",
  is_shared_service_staff_description:
    "Staf bisa lihat servis berlangsung staf lainnya",
  is_not_submit_service_staff: "Staf tidak bisa submit servis",
  is_not_submit_service_staff_description:
    "Hanya admin yang bisa submit servis",
  is_hide: "Toko akan disembunyikan",
  is_hide_description: "Toko tidak dapat dilihat oleh toko lainnya",
};
