export default {
  tag: "Tag",
  next_service: "Servis Selanjutnya",
  service_history: "Riwayat Servis",
  back: "Kembali",
  service_detail: "Detail Service",
  last_service: "Servis Terakhir",
  date: "Tanggal",
  detail: "Detail",
  shop: "Shop",
  personal: "Personal",
  transaction_date: "Tanggal Servis",
  description: "Keterangan",
  kilometer_service: "Kilometer Servis",
  service_item: "Item Servis",
  staff_shop: "Staff",
  reminder: "REMINDER",
  warranty: "GARANSI",
  document: "DOKUMEN",

  private_product: "Maaf, produk yang di scan bersifat private.",
  private_product_canAccess:
    "Aset ini bersifat private, hanya bisa diakses oleh partner shop dan yang memiliki hak akses.",
  product_not_found: "Maaf, kami tidak dapat menemukan produk ini.",
  ensure_valid_barcode:
    "Pastikan bahwa barcode QRID yang anda scan adalah valid",
  back_to_homepage: "Kembali ke halaman utama",
  not_found_phrase_1: "Wah, sepertinya kamu belum ada aplikasi QRID nih.",
  not_found_phrase_2: "Yuk download aplikasi QRID sekarang!",

  qr_code_still_empty: "QR Code masih kosong",
  ensure_qr_code_filled: "Pastikan bahwa QR Code telah terisi",
};
