import invariant from "invariant";
import * as React from "react";

interface NotificationProviderProps {
  children: React.ReactNode;
}
interface ContextProps {
  notification: boolean;
  setNotification: (value: boolean) => void;
}

const notificationContext = React.createContext<ContextProps>({
  notification: false,
  setNotification: () => {},
});

export function NotificationProvider(props: NotificationProviderProps) {
  const [notification, setNotification] = React.useState<boolean>(false);
  return (
    <notificationContext.Provider value={{ notification, setNotification }}>
      {props.children}
    </notificationContext.Provider>
  );
}
export function useNotification() {
  const context = React.useContext(notificationContext);
  invariant(
    context !== undefined,
    "useNotification must be used insied notificationContext.Provider"
  );
  return context;
}
