import { init, RematchRootState } from "@rematch/core";
import loadingPlugin from "@rematch/loading";
import createPersistPlugin, { getPersistor } from "@rematch/persist";
import reduxPersistTransformFilter from "common/helpers/redux-persist-transform-filter";
import localStorage from "redux-persist/lib/storage";

import auth from "./auth";
import otp from "./otp";

const models = {
  auth,
  otp,
};

const persistPlugin = createPersistPlugin({
  key: "root",
  whitelist: ["auth", "otp"],
  throttle: 2000,
  version: 1,
  storage: localStorage,
  transforms: [
    reduxPersistTransformFilter([
      {
        key: "auth",
        whitelists: ["data", "account", "credentials"],
      },
    ]),
  ],
});

export const store = init({
  models,
  plugins: [persistPlugin as any, loadingPlugin({})],
});

export const persistor = getPersistor();

export type RematchDispatch = typeof store.dispatch;
export type RootState = RematchRootState<typeof models>;
