import QHeader from "components/widgets/q-header";
import { store } from "models";
import { SessionProvider } from "next-auth/react";
import * as React from "react";
import * as Redux from "react-redux";

import PublicLayout from "./public-layout";

interface DefaultLayoutProps {
  children: React.ReactNode;
  pageProps: any;
}

export default function DefaultLayout(props: DefaultLayoutProps) {
  const { session } = props.pageProps;

  return (
    <SessionProvider session={session} basePath="/api/auth">
      <Redux.Provider store={store}>
        <div>
          <main style={{ display: "flex", flexDirection: "column" }}>
            <QHeader />
            <PublicLayout>{props.children}</PublicLayout>
          </main>
        </div>
      </Redux.Provider>
    </SessionProvider>
  );
}
